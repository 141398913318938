<template>
  <div v-if="data" style="width: 100%; height: 100%">

    <div v-if="docType === 'MS_DOC'">
      <div class="docx-wrapper">
        <div id="doc" class="full-width" v-html="docFrame"/>
      </div>

      <v-progress-linear v-show="progress < 100 && progress > 0" :value="progress" class="doc-progress-loader"/>
    </div>

    <pdf-viewer v-else-if="docType === 'PDF'" :url="url"/>

    <v-img v-else-if="isImage" :src="url" max-width="100%"/>

    <div v-if="docType && isNotSupportedFormat" class="pa-4 text-center">
      <img v-if="fileType" :src="`/image/${fileType}.png`"/>
      <p>
        {{ $t('unsupportedDocumentFormat') }}
      </p>
      <v-btn large @click="download">
        <v-icon left>file_download</v-icon>
        {{ $t('download') }}
      </v-btn>
    </div>

  </div>
</template>

<script>

import PdfViewer from '@/components/utils/pdf/PdfViewer.vue'
import { EventBus } from '@/event-bus'
import downloadFile from '@/services/file-downloader'
import sharedMessages from '@/componet-locale/doc-view/messages'

export default {
  name: 'PublicDocViewer',
  i18n: {sharedMessages},
  components: { PdfViewer },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      docFrame: '',
      progress: 0,
      content: {},
      doc: null
    }
  },
  mounted () {
    this.getDoc()
  },
  methods: {
    async getDoc () {
      this.$axios.get('public-data/get-doc-version/' + this.data.id)
        .then(({ data }) => {
          this.doc = data
          if (data.type === 'MS_DOC') {
            this.loadBody()
          }
        })
        .catch(() => EventBus.$eimt('showErrorMessage', 'error'))
    },
    async loadBody (body = '', partNumber = 1) {
      let response = await this.$axios.get('/public-data/get-doc-version-body/' + this.data.id, {
        params: { part: partNumber },
        timeout: 240000
      })
      let part = response.data
      this.progress = partNumber / part.totalParts * 100
      body += part.body

      this.docFrame = body

      if (part.totalParts > partNumber) {
        await this.loadBody(body, partNumber + 1)
      } else {
        return body
      }
    },
    download () {
      const extension = this.doc.fileName.split('.').pop()
      const version = this.doc.name ? `(${this.doc.name})` : ''
      const fileName = `${this.data.name} ${version}.${extension}`
      this.isFileDownloading = true
      downloadFile(`/public-data/get-doc-version-file/${this.doc.id}`, fileName)
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('fileDownloadError'))
        })
        .finally(() => this.isFileDownloading = false)
    },
  },
  computed: {
    fileType () {
      if (this.doc && this.doc.fileName) {
        let types = ['doc', 'jpg', 'pdf', 'png', 'ppt', 'txt', 'xls', 'zip']

        let fileExt = this.doc.fileName.split('.').pop().toLowerCase()
        if (types.includes(fileExt)) return fileExt

        switch (fileExt) {
          case 'csv':
          case 'xlsx':
            return 'xls'
          case 'jpeg':
            return 'jpg'
          case 'rtf':
          case 'docx':
            return 'doc'
          case 'pptx':
            return 'ppt'
        }
      }
      return 'unknown-icon'
    },
    url () {
      if (this.doc && this.doc.filePath) {
        return this.$axios.defaults.baseURL + '/public-data/get-doc-version-file/' + this.doc.id
      } else {
        return ''
      }
    },
    docType () {
      if (this.doc && this.doc.type) {
        return this.doc.type
      }
    },
    docFormat () {
      if (this.doc && this.doc.type) {
        return this.doc.fileName.split('.').pop().toLowerCase()
      }
    },
    isNotSupportedFormat () {
      let supportedTypes = ['MS_DOC', 'PDF']
      return !this.isImage && !supportedTypes.includes(this.docType)
    },
    isImage () {
      let imageFormats = ['png', 'jpeg', 'jpg', 'gif', 'bmp']
      return imageFormats.includes(this.docFormat)
    }
  }
}
</script>

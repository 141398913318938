<template>
  <v-dialog v-model="dialog" fullscreen>
    <v-card v-if="loading" height="100vh" width="100%">
      <v-progress-circular class="ma-auto" color="primary" size="24" />
    </v-card>
    <v-card
      v-else-if="data"
      class="d-flex flex-column"
      height="90vh"
      width="100%"
    >
      <v-card-title class="d-flex flex-nowrap">
        <div>{{ data.name }}</div>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="flex-grow-1 overflow-y-auto pa-0">
        <component :is="getComponent" :data="data" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PublicMapViewer from "@/components/public-data/PublicMapViewer";
import PublicDocViewer from "@/components/public-data/PublicDocViewer";
import PublicEtViewer from "@/components/public-data/PublicEtViewer";
import PublicDataException from "@/components/public-data/PublicDataException";
import { EventBus } from "../../event-bus";

export default {
  name: "PublicDataViewDialog",
  data: () => ({
    dialog: false,
    data: null,
    loading: false
  }),
  methods: {
    open(dataId) {
      this.loading = true;
      this.dialog = true;
      this.$axios
        .get("public-data/get", {
          params: {
            id: dataId
          }
        })
        .then(res => {
          this.data = res.data;
        })
        .catch(() => {
          this.close();
          EventBus.$emit("showInfoMessage", "Данные недоступны");
        })
        .finally(() => (this.loading = false));
    },
    close() {
      this.dialog = false;
      this.data = null;
      this.loading = false;
    }
  },
  computed: {
    getComponent() {
      switch (this.data.dataType) {
        case "MAP":
          return PublicMapViewer;
        case "DOC":
          return PublicDocViewer;
        case "ET":
          return PublicEtViewer;
        default:
          return PublicDataException;
      }
    }
  }
};
</script>

<template>
  <v-dialog v-model="dialog" width="900">
    <v-card>
      <v-card-title>
        {{ category ? category.name : "" }}
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0 pt-2">
        <v-skeleton-loader v-if="loading" :loading="loading" type="list-item" />
        <v-list v-else>
          <template v-for="item in adList">
            <v-divider class="my-0" />
            <v-list-item :key="item.id" :item="item" @click="openAD(item)">
              <v-list-item-title>
                {{ item ? item.name : "" }}
              </v-list-item-title>
              <v-list-item-action>
                <v-btn class="mr-4" icon @click.stop="openInfoDialog(item)">
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>

    <v-dialog v-if="infoItem" v-model="infoDialog" width="800">
      <v-card class="pa-3">
        <v-card-title class="pb-0">
          {{ infoItem.name }}
          <v-spacer />
          <span class="grey--text">ID:{{ infoItem.id }}</span>
        </v-card-title>
        <v-card-text class="py-0">
          <div v-if="infoItem.description" class="mt-5">
            <div class="subtitle-1">Описание</div>
            <div class="body-2 pb-2" v-html="infoItem.description" />
          </div>
          <div v-if="infoItem.provider" class="mt-5">
            <div class="subtitle-1">Источник</div>
            <span v-if="infoItem.provider" class="font-weight-medium">{{
              infoItem.provider.name
            }}</span>
          </div>
          <div v-if="infoItem.keywords.length > 0" class="mt-5">
            <div class="subtitle-1">Ключевые слова</div>
            <v-chip
              v-for="keyword in infoItem.keywords"
              class="ma-1"
              color="primary"
              label
              outlined
              small
            >
              {{ keyword.value }}
            </v-chip>
          </div>

          <div class="my-5">
            <div class="subtitle-1 mb-1">Создан</div>
            <div class="d-flex mt-2">
              <user-avatar :user="infoItem.owner" />
              <div>
                <span class="body-2">
                  {{ infoItem.owner.firstName }}
                  {{ infoItem.owner.lastName }}
                </span>
                <br />
                <span class="body-2">
                  {{ formatDate(infoItem.creationDate) }}
                </span>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn outlined @click="closeInfoDialog">
            {{ $t("button.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <public-data-view-dialog ref="publicDataViewDialog" />
  </v-dialog>
</template>

<script>
import SimpleDataRow from "@/components/utils/SimpleDataRow.vue";
import { EventBus } from "@/event-bus";
import UserAvatar from "@/components/utils/UserAvatar.vue";
import AbstractDataDetailsDialog from "@/components/utils/AbstractDataDetailsDialog.vue";
import PublicDataViewDialog from "./PublicDataViewDialog.vue";

export default {
  name: "DataListDialog",
  components: {
    PublicDataViewDialog,
    AbstractDataDetailsDialog,
    UserAvatar,
    SimpleDataRow
  },
  data: () => ({
    dialog: false,
    category: null,
    adList: [],
    loading: false,
    infoItem: null,
    infoDialog: false
  }),
  methods: {
    openAD(ad) {
      this.$refs.publicDataViewDialog.open(ad.id);
    },
    open(category) {
      this.dialog = true;
      this.category = category;
      this.loading = true;
      this.$axios
        .get(`public-data/categories/${category.id}`)
        .then(response => (this.adList = response.data))
        .catch(() => EventBus.$emit("showErrorMessage", this.$t("error")))
        .finally(() => (this.loading = false));
    },
    openInfoDialog(item) {
      this.infoItem = item;
      this.infoDialog = true;
    },
    closeInfoDialog() {
      this.infoDialog = false;
      this.infoItem = null;
    }
  }
};
</script>
